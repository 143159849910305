body{
  overflow-x:hidden; 
  background-color: black;
}

.box-gallery img {
  }

  .box-gallery,
.box-gallery div {
}

/* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Pulkkis.jpg"); */

/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: url("Pulkkis.jpg");

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image: url("Pulkkis.jpg");
  
    /* Set a specific height */
    height: 30%;
  
    /* Position and center the image to scale nicely on all screens */
    background-position: top right -100px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .mobileLeftmarg{
    margin-left: 5px !important;
  }
}

.modal-content {
  background-color: black !important;
  color: lightgray !important;
}
.badgefont {
  font-size: 10px !important;
  font-weight: lighter !important;
  padding: 1px !important;
}

.titlefont {
  font-family: Georgia, serif;
}
a:link { text-decoration: none; }
/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.flip-box {
  /* background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
  */
}

.workTitle{
  font-size: 1.1rem !important;
}

.workTitle2{
  font-size: 0.9rem !important;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}